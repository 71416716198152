import { useState } from "react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { getPatternByUserName } from "../../services/ApiService";
import axios from "axios";

const OTP = () => {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [third, setThird] = useState("");
  const [fourth, setFourth] = useState("");
  const [fivth, setFivth] = useState("");
  const [sixth, setSixth] = useState("");
  const [secret, setSecret] = useState("");
  let navigate = useNavigate();
  const location = useLocation();
  let messageEntered = location.state.rawMessageEntered;

  let localtoken = localStorage.getItem("token");
  let token = JSON.parse(localtoken).token;

  const getSecrectKey = (id) => {
    return axios
      .get(
        `https://sccom.realware.tech/sccom/getEncodedPattern?pattern=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      )
      .then((res) => {
        console.log(res);
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
        return null; // handle error
      });
  };

  const validateOTP = () => {
    let value =
      first + "" + second + "" + third + "" + fourth + "" + fivth + "" + sixth;
    console.log("value:::", value);

    getPatternByUserName(token).then(async (resp) => {
      console.log("resp.data", resp.data);
      let pattern = resp.data.Pattern;

      // Fetch the secret key based on the pattern
      const secretKey = await getSecrectKey(pattern);

      if (secretKey) {
        let key = secretKey + value + "00";
        console.log("Key is ..........", key);

        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(messageEntered),
          key
        ).toString();
        console.log("key", btoa(key));

        navigate("/SendTo", {
          state: {
            encryptedMessage: encrypted,
            secretKey: btoa(key),
            subject: location.state.subject,
            forwardable: location.state.forwardable,
            replyAll: location.state.replyAll,
            attachments: location.state.attachments,
            expiry: location.state.expiry,
            downloadOnly: location.state.downloadOnly,
            viewOnly: location.state.viewOnly,
          }
        });
      } 
      else {
        console.log("Failed to fetch the secret key.");
      }
    });
  };

  function jumpToNextPcInput(currentIndex) {
    let value = currentIndex;
    console.log("value", value);
  }

  const clickEvent = (e, last) => {
    const currentInputValue = e.target.value.trim();
    if (currentInputValue !== "") {
      document.getElementById(last).focus();
    }
  };

  return (
    <div className="h-screen w-full grid place-items-center">
      <div className="flex justify-center mt-8">
        <label className="text-black font-bold ml-5">
          Enter 6 digit passphrase
        </label>
      </div>
      <div style={myComponentStyle.inputfield} className="gap-x-8">
        <input
          maxLength={1}
          autoComplete="off"
          id="ist"
          onKeyUp={(e) => clickEvent(e, "sec")}
          required
          style={myComponentStyle.input}
          onChange={(event) => {
            setFirst(event.target.value);
          }}
        />
        <input
          maxLength={1}
          autoComplete="off"
          id="sec"
          onKeyUp={(e) => clickEvent(e, "third")}
          style={myComponentStyle.input}
          onChange={(event) => {
            setSecond(event.target.value);
          }}
        />
        <input
          maxLength={1}
          autoComplete="off"
          id="third"
          onKeyUp={(e) => clickEvent(e, "fourth")}
          style={myComponentStyle.input}
          onChange={(event) => {
            setThird(event.target.value);
          }}
        />
        <input
          maxLength={1}
          autoComplete="off"
          id="fourth"
          onKeyUp={(e) => clickEvent(e, "fifth")}
          style={myComponentStyle.input}
          onChange={(event) => {
            setFourth(event.target.value);
          }}
        />
        <input
          maxLength={1}
          autoComplete="off"
          id="fifth"
          onKeyUp={(e) => clickEvent(e, "sixth")}
          style={myComponentStyle.input}
          onChange={(event) => {
            setFivth(event.target.value);
          }}
        />
        <input
          maxLength={1}
          autoComplete="off"
          id="sixth"
          style={myComponentStyle.input}
          onChange={(event) => {
            setSixth(event.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: "-36%" }}>
        <button style={myComponentStyle.submit} onClick={validateOTP}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default OTP;

const myComponentStyle = {
  inputfield: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "-20%",
  },
  input: {
    height: "3em",
    width: "3em",
    borderBottom: "2px solid #6676aa82",
    textAlign: "center",
    fontSize: "1.5em",
    backgroundColor: "#ffffff",
    outline: "none",
  },
  submit: {
    backgroundColor: "#044ecf",
    border: "none",
    outline: "none",
    fontSize: "1.2em",
    padding: "0.8em 2em",
    color: "#ffffff",
    borderRadius: "0.1em",
    margin: "1em auto 0 auto",
    cursor: "pointer",
  },
};