import React, { useEffect } from "react";
import { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { deletePatternMessage, getDocuments } from '../../services/ApiService'
import fileDownload from 'js-file-download'
import TextField from '@mui/material/TextField';
import { forwardMessage, replyToMessage, getPatternByUserName } from "../../services/ApiService";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ReplyIcon from '@mui/icons-material/Reply';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import parse from 'html-react-parser'
import { generateDecryptSessionKey, getSecretePatternToReadAsOTP } from "../../services/ApiService";
import AccordionMessage from "./accordion";
import FileViewer from '../FileViewer';
function getDate() {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const year = today.getFullYear();
    const date = (today.getDate()).toString().padStart(2, "0");
    const time = today.getHours() + ':' + today.getMinutes();
    return `${date}-${month}-${year} ${time}`;
}
const MailBody = () => {
    const location = useLocation();
    let navigate = useNavigate();
    let fianlArray = [];
    const [clickStatus, setClickStatus] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState('');
    const [to, setTo] = useState(location.state.zeroIndex.sender)
    const [selectedFiles, SetAttachments] = useState([]);
    const [rawAttachments, SetRawAttachments] = useState([]);
    const [forwardSelect, setForwardSelect] = useState(false);
    const [replySelect, setReplySelect] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const [downloadOnly, setDownloadOnly] = useState(false);
    const [value, setValue] = React.useState('0');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentDate, setCurrentDate] = useState(getDate());
    const [UUID, setUUID] = useState('');
    const [data1, setdata1] = useState('')
    const [fullDataFirstIndex, setfullDataFirstIndex] = useState({});
    const [fullData, setfullData] = useState([]);
    const [dataResponse, setdataResponse] = useState('');
    const [getAttachments, setgetAttachments] = useState([]);
    const [removeArray, setRemoveArray] = useState([]);
    let keyKeyKey
    let removeDATAdata = []
    const ref = useRef(null);

    const [accordionArray, setAccordionArray] = useState([])



    const [documentData, setDocumentData] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [showOtherComponent, setShowOtherComponent] = useState(false);


    // useEffect(() => {
    //     if (fullDataFirstIndex) {
    //         getFirstMessage();
    //     }
    // }, []);

    // useEffect(() => {
    //     setfullDataFirstIndex(location.state.fullData.messages[0])
    //     console.log("MailBody,", fullDataFirstIndex)
    // }, [])

    // useEffect(() => {
    //     setfullData(location.state.fullData);
    //     console.log("fullData", fullData)
    // }, [])

    useEffect(() => {
        // getLocationDetails()
        getFirstMessage()
    }, [])

    const getLocationDetails = () => {
        setfullDataFirstIndex(location.state.fullData.messages[0])
        console.log("MailBody,", fullDataFirstIndex)
        setfullData(location.state.fullData);
        console.log("fullData", fullData)
        if (fullDataFirstIndex && fullData) {
            console.log("valid")
            getFirstMessage();
        }

    }
    const getFirstMessage = () => {
        let localtoken = localStorage.getItem('token');
        if (!localtoken) {
            console.error("Token not found");
            return;
        }
        let token = JSON.parse(localtoken).token;
        let obj = {
            uuid: location.state.zeroIndex.uuid,
            mainUuid: location.state.zeroIndex.repliedUuid,
            messageType: location.state.zeroIndex.messageType,
        };
        getSecretePatternToReadAsOTP(token, obj).then(response => {
            if (response && response.data) {
                let pattern = response.data.secretPattern;
                let loginUserId = localStorage.getItem('userid');
                const reversPattern = pattern.split("").reverse().join(""); // Reverse pattern
                let obj1 = {
                    uuid: location.state.zeroIndex.uuid,
                    mainUuid: location.state.zeroIndex.repliedUuid,
                    messageType: location.state.zeroIndex.messageType,
                    patternCode: reversPattern,
                    sender: loginUserId
                };

                generateDecryptSessionKey(token, obj1).then(response => {
                    setdataResponse(response.data.message.message);

                    if (response.data && response.data.message) {
                        try {
                            let decryptedMessage = response.data.message.message.encryptedMessage;
                            let reverseDecryptedOTP = response.data.message.message.decryptedOTP;
                            let originalOTP = reverseDecryptedOTP.split("").reverse().join("");

                            // Construct the key
                            let keyKeyKey = reversPattern + originalOTP + '00';

                            console.log("Decrypted Message:", decryptedMessage);
                            console.log("Decryption Key:", keyKeyKey);
                            setgetAttachments(response.data.message.message.attachments);
                            console.log("getAttachments", Array.isArray(getAttachments))

                            // Decrypt the message
                            const decrypted = CryptoJS.AES.decrypt(decryptedMessage, keyKeyKey).toString(CryptoJS.enc.Utf8);
                            console.log("Decrypted Data:", decrypted);

                            if (decrypted) {
                                setdata1(JSON.parse(decrypted));
                            } else {
                                console.error("Failed to decrypt message");
                            }
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }
                    } else {
                        console.error("Response message is invalid or missing");
                    }
                }).catch(err => {
                    console.error("Error generating decrypt session key:", err);
                });
            } else {
                console.error("Response or data is invalid");
            }
        }).catch(err => {
            console.error("Error fetching secret pattern:", err);
        });
    };

    // const getFirstMessage = () => {
    //     let localtoken = localStorage.getItem('token');
    //     if (!localtoken) {
    //         console.error("Token not found");
    //         return;
    //     }
    //     let token = JSON.parse(localtoken).token;
    //     let obj = {
    //         uuid: location.state.zeroIndex.uuid,
    //         mainUuid: location.state.zeroIndex.repliedUuid,
    //         messageType: location.state.zeroIndex.messageType,
    //     };
    //     getSecretePatternToReadAsOTP(token, obj).then(response => {
    //         if (response && response.data) {
    //             let pattern = response.data.secretPattern;
    //             let loginUserId = localStorage.getItem('userid');
    //             const reversPattern = pattern.split("").reverse().join("");
    //             let obj1 = {
    //                 uuid: location.state.zeroIndex.uuid,
    //                 mainUuid: location.state.zeroIndex.repliedUuid,
    //                 messageType: location.state.zeroIndex.messageType,
    //                 patternCode: reversPattern,
    //                 sender: loginUserId
    //             };
    //             generateDecryptSessionKey(token, obj1).then(response => {
    //                 if (response.data && response.data.message) {
    //                     try {
    //                         let decryptedMessage = response.data.message.message.encryptedMessage;
    //                         console.log("++++++++++++++++", decryptedMessage)
    //                         const decrypted = CryptoJS.AES.decrypt(decryptedMessage, keyKeyKey).toString(CryptoJS.enc.Utf8);
    //                         console.log("??????????????????", decrypted)
    //                         if (decrypted) {
    //                             setdata1(JSON.parse(decrypted));
    //                         } else {
    //                             console.error("Failed to decrypt message");
    //                         }
    //                     } catch (error) {
    //                         console.error("Error parsing JSON:", error);
    //                     }
    //                 } else {
    //                     console.error("Response message is invalid or missing");
    //                 }
    //             }).catch(err => {
    //                 console.error("Error generating decrypt session key:", err);
    //             });
    //         } else {
    //             console.error("Response or data is invalid");
    //         }
    //     }).catch(err => {
    //         console.error("Error fetching secret pattern:", err);
    //     });
    // };

    // const getFirstMessage = () => {
    //     // setfullDataFirstIndex(location.state.fullData.messages[0])
    //     console.log("zeroIndex", location.state.zeroIndex)
    //     let localtoken = localStorage.getItem('token');
    //     console.log("localtoken", localtoken)
    //     let token = JSON.parse(localtoken).token;
    //     let obj = {
    //         uuid: location.state.zeroIndex.uuid,
    //         mainUuid: location.state.zeroIndex.repliedUuid,
    //         messageType: location.state.zeroIndex.messageType,
    //     }
    //     console.log("obj", obj)
    //     getSecretePatternToReadAsOTP(token, obj).then(response => {
    //         let pattern = response.data.secretPattern;
    //         let loginUserId = localStorage.getItem('userid')
    //         const reversPattern = response.data.secretPattern.split("").reverse().join(""); //Pattern
    //         let obj1 = {
    //             uuid: location.state.zeroIndex.uuid,
    //             mainUuid: location.state.zeroIndex.repliedUuid,
    //             messageType: location.state.zeroIndex.messageType,
    //             patternCode: reversPattern,
    //             sender: loginUserId
    //         }
    //         console.log("obj", obj1)
    //         generateDecryptSessionKey(token, obj1).then(response => {
    //             console.log("response", response.data.message)
    // setdataResponse(response.data.message.message);
    //             console.log("setdataResponse", response.data.status)
    //             if (response.data.message.status === 'success') {
    //                 let reverseDecryptedOTP = response.data.message.message.decryptedOTP;
    //                 let originalOTP = reverseDecryptedOTP.split("").reverse().join("");
    //                 console.log("originalOTP", originalOTP);
    //                 keyKeyKey = reversPattern + originalOTP + '00';
    //                 console.log("key", keyKeyKey, response.data.message.message.encryptedMessage);
    //                 const decrypted = CryptoJS.AES.decrypt(response.data.message.message.encryptedMessage, keyKeyKey).toString(CryptoJS.enc.Utf8);
    //                 console.log("222", decrypted)
    //                 setdata1(JSON.parse(decrypted))
    //                 console.log("data1", data1);
    //                 setgetAttachments(response.data.message.message.attachments);
    //                 console.log("getAttachments", Array.isArray(getAttachments))
    //             }
    //             // removArrayByIndex()
    //         })
    //     })




    // }

    const removArrayByIndex = () => {
        let removeArray1 = location.state.fullData.messages
        console.log("removeArray", removeArray1)
        removeDATAdata = removeArray1.splice(0, 1);
        console.log("removeDATA", removeDATAdata)
        setRemoveArray(removeDATAdata)
        console.log("remove", removeArray)
    }

    const replyClick = (evnt) => {
        console.log("evnt.repliedUuid", evnt.repliedUuid)
        if (evnt.repliedUuid === null) {
            setUUID(evnt.uuid)
        }
        else {
            setUUID(evnt.repliedUuid)
        }
        setClickStatus('reply')
        // console.log("click reply", UUID);
        // setUUID(uuid)
        setShowModal(true);
        if (
            document.getElementById("menu") &&
            document.getElementById("main")
        ) {
            document.getElementById("menu").style.width = "250px";
            document.getElementById("main").style.marginRight = "250px";
        }
    }
    const replyAllClick = (uuid) => {
        setClickStatus('replyAll')
        setUUID(uuid)
        setShowModal(true);
        if (
            document.getElementById("menu") &&
            document.getElementById("main")
        ) {
            document.getElementById("menu").style.width = "250px";
            document.getElementById("main").style.marginRight = "250px";
        }

    }
    const forwardClick = (uuid) => {
        setClickStatus('forward')
        setUUID(uuid);
        setShowModal(true);
        if (
            document.getElementById("menu") &&
            document.getElementById("main")
        ) {
            document.getElementById("menu").style.width = "250px";
            document.getElementById("main").style.marginRight = "250px";
        }
    }

    const closeAside = () => {
        setShowModal(false);
        if (
            document.getElementById("menu") &&
            document.getElementById("main")
        ) {
            document.getElementById("menu").style.width = "0";
            document.getElementById("main").style.marginRight = "0";
        }
        // menu.classList.remove('ml-0');
        // menu.classList.add('-ml-56');
    }

    const getFileType = () => {
        switch (fileType) {
            case 'pdf':
                return 'application/pdf';
            case 'image':
                return 'image/png';
            default:
                return '';
        }
    };

    const documents = (fileId, filename, message) => {
        console.log("location.state.downloadOnly", message.repliedUuid)
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token;
        if (message.repliedUuid === null) {
            console.log("message.repliedUuid", message.repliedUuid)
            message.repliedUuid = ''
        }
        let obj = {
            uuid: message.uuid,
            mainUuid: message.repliedUuid,
            messageType: message.messageType,
            fileId: fileId,
        }
        console.log("obj", obj)
        getDocuments(token, obj).then((response) => {
            // console.log("response.data.attachments[0]", response.data['attachments'].filename)
            if (response.data.status === 'success') {
                setDocumentData(response.data['attachments'].data)
                setFileType(response.data['attachments'].fileType);
                const linkSource = `data:${response.data['attachments'].fileType};base64,${response.data['attachments'].data}`;
                console.log("fileType:::", dataResponse.downloadOnly, dataResponse.viewOnly)
                if (dataResponse.downloadOnly === true) {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = filename;
                    downloadLink.click();
                }
                if (dataResponse.viewOnly === true) {
                    setShowOtherComponent(!showOtherComponent);
                    //         console.log("viewOnly")
                    //         if (response.data['attachments'].fileType === 'image/png') {
                    //             console.log("image")
                    //             var image = new Image();
                    //             image.src = `data:${response.data['attachments'].fileType};base64,` + response.data['attachments'].data;

                    //             var w = window.open("", '', "toolbars=no,scrollbars=no,status=no,resizable=no");
                    //             w.document.write("<html style={height:20px;width:20px;}<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //             w.document.write(image.outerHTML);
                    //         }
                    //         else {
                    //             let pdfWindow = window.open("");
                    //             pdfWindow.document.write("<html<head><title>" + filename + "</title><style>body{margin: 0px;}</style></head>");
                    //             pdfWindow.document.write(`<body><embed width='100%' height='100%' src=data:${response.data['attachments'].fileType};base64, " + encodeURI(response.data['attachments'].data) + "#toolbar=0&navpanes=0&scrollbar=0></embed></body></html>`);
                    //             pdfWindow.document.close();
                    //         }
                    //     }
                }
            }
        })
    }


    const handleChangeinputAttachments = (e) => {
        const files = e.target.files;
        let obj = {
            name: files[0]['name'],
            size: files[0]['size'],
            type: files[0]['type']
        }
        // SetRawAttachments(rawAttachments => [...rawAttachments, files]);
        SetRawAttachments(files);
        SetAttachments(selectedFiles => [...selectedFiles, obj]);
        // console.log("setSelectedFiles", selectedFiles)
    };
    const deleteAttachment = (name) => {
        const newArray = selectedFiles.filter(obj => {
            return obj.name !== name;
        })
        SetAttachments(newArray);
        SetRawAttachments(newArray);
        // SetAttachments(selectedFiles.splice(index, index))
    }

    const open = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleChangeRadio = (event) => {
        setValue(event.target.value);
    };
    const handleClick = () => {
        ref.current?.click();
    };


    const Send = () => {
        var text = document.getElementById('input').innerHTML;
        let localtoken = localStorage.getItem('token');
        let token = JSON.parse(localtoken).token
        const dataArray = [];
        // if (to.length === 0) {
        //     console.log("1111")
        //     setTo(location.state.zeroIndex.sender);
        //     dataArray.push(to);
        // }
        dataArray.push(to);
        console.log("dataArray", dataArray)
        // return
        const cc = [];
        let forwardobj = {
            sender: localStorage.getItem('userid'),
            receiver: dataArray,
            uuid: UUID,
            cc: cc
        }
        if (clickStatus === 'forward') {
            forwardMessage(token, forwardobj).then(response => {
                console.log(response);
            })
        }
        else if (clickStatus === 'reply' || clickStatus === 'replyAll') {
            let reverseDecryptedOTP = '765424';
            let originalOTP = reverseDecryptedOTP.split("").reverse().join("");
            console.log("originalOTP", originalOTP);
            getPatternByUserName(token).then(resp => {
                console.log("resp", resp)
                if (resp.data.status === 'success') {
                    let patternCode = resp.data.Pattern;
                    let key = patternCode + originalOTP + '00';
                    const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(text), key).toString();
                    let obj = {
                        sender: localStorage.getItem('userid'),
                        receiver: dataArray,
                        message: encryptedMessage,
                        dateTime: currentDate,
                        forwardable: forwardSelect,
                        replyAll: replySelect,
                        expiry: value,
                        downloadOnly: downloadOnly,
                        viewOnly: viewOnly,
                        cc: cc,
                        uuid: UUID,
                        key: btoa(patternCode + originalOTP + '00')
                    }
                    console.log("obj", obj)
                    // return
                    replyToMessage(token, obj, rawAttachments).then(response => {
                        console.log(response);
                        if (response.data.status === "Reply sent successfully") {
                            navigate('/Inbox');
                        }
                    })
                }
            })

        }

    }

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default context menu 
    };

    const returnMessage = (data) => {
        console.log("data", data, keyKeyKey)
        const decrypted = CryptoJS.AES.decrypt(data, keyKeyKey).toString(CryptoJS.enc.Utf8);;
        console.log("decrypted", decrypted)
        return JSON.parse(decrypted);
    }

    const handleCloseViewer = () => {
        setShowOtherComponent(!showOtherComponent);
    };

    return (
        showOtherComponent ?
            <div>
                <button className="float-right text-red-500 text-lg font-extrabold pr-5" title="close Viewer" onClick={handleCloseViewer}>X</button>
                <FileViewer base64Data={documentData} fileType={fileType} />
            </div>
            :
            <div className='flex' style={{ userSelect: "none" }}>

                {/* <MyModal showModal={showModal} setShowModal={setShowModal} /> */}
                <div id="main">
                    <h2 class="text-3xl font-normal mb-5 mt-2" style={{ padding: "30px 0 8px 95px" }}>
                        {location.state.zeroIndex.subject}
                    </h2>

                    {location.state.withOutZeroIndex !== 0 &&
                        < AccordionMessage arrayOfData={location.state.withOutZeroIndex} patternCode={location.state.patternCode} />
                    }

                    <div class="text-black border-b border-grey-600 m-9">
                        <div>

                            <div>
                                <div class="mt-8">
                                    <Stack direction="row" spacing={3}>
                                        <Avatar className="uppercase" style={{ backgroundColor: "#081a51d6" }}>{location.state.zeroIndex.sender.charAt(0)}</Avatar>
                                        <div className="justify-items-start">
                                            <span class="flex"> {location.state.zeroIndex.sender}</span>
                                            <span class="text-xs text-slate-400 flex">
                                                {/* to {localStorage.getItem('userid')} */}
                                                {location.state.zeroIndex.receiver && location.state.zeroIndex.receiver.join(",")}
                                            </span>
                                        </div>
                                        <div style={{ width: "91%", justifyContent: 'end', display: 'flex' }}>
                                            <span class="text-xs text-slate-400  mt-5 flex justify-items-end" >
                                                {location.state.zeroIndex.sentTime}
                                            </span>

                                            {/* <span class="ml-4 mt-1 text-slate-400">
                                            <IconButton aria-label="Not Stared">
                                                <StarBorderIcon />
                                            </IconButton>
                                        </span> */}
                                            {/* <span class="ml-4 mt-1 text-slate-400">
                                            <IconButton aria-label="Reply" title='Reply' disabled={!dataResponse.reply}>
                                                <ReplyIcon />
                                            </IconButton>
                                        </span> */}
                                            {/* <span class="ml-4 mt-1 text-slate-400" title='more'>
                                            <IconButton aria-label="More">
                                                <MoreVertIcon />
                                            </IconButton>
                                        </span> */}

                                        </div>
                                    </Stack>
                                    <div style={{ whiteSpace: 'pre-wrap', marginLeft: '10px', marginLeft: '6.5%', marginTop: "2%", fontWeight: "normal" }}>
                                        <div >
                                            {parse(data1)}
                                        </div>


                                    </div>
                                    <div className="flex">
                                        {Array.isArray(getAttachments)}
                                        {
                                            getAttachments.length !== 0 ? (
                                                getAttachments.map((file, index) => {
                                                    return (
                                                        <div className="border border-[#e5e5e5] h-[120px] w-[180px] ml-[55px] mb-[3px] mt-[80px] cursor-pointer"
                                                            onClick={() => documents(file.fileId, file.filename, location.state.zeroIndex)}>
                                                            <div className='relative h-[85px] items-center flex justify-center'>
                                                                <img src="https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_image_x16.png" className="w-[25px] h-[25px] text-center" />
                                                            </div>
                                                            <div className='border-t-[1.5px] border-[#e5e5e5] bg-[#f5f5f5] text-[12px] h-[33px] flex items-center whitespace-nowrap overflow-hidden'>
                                                                <span className='truncate ...'>{file.filename}</span>
                                                            </div>
                                                            {/* <span> {file.size}</span> */}
                                                        </div>
                                                    )

                                                })
                                            ) : ""

                                        }
                                    </div>
                                    <div class="mt-10 ml-14 mb-4">
                                        <Stack direction="row" spacing={1}>
                                            <Chip
                                                label="Reply"
                                                deleteIcon={<DoneIcon className="cursor-pointer" />}
                                                variant="outlined"
                                                className="cursor-pointer"
                                                onClick={() => replyClick(location.state.zeroIndex)}
                                            />
                                            <Chip disabled={!dataResponse.reply}
                                                label="Reply all"
                                                deleteIcon={<DeleteIcon className="cursor-pointer" />}
                                                variant="outlined"
                                                className="cursor-pointer"
                                                onClick={() => replyAllClick(location.state.uuid)}
                                            />
                                            <Chip disabled={!dataResponse.forwardable}
                                                label="Forward"
                                                deleteIcon={<DeleteIcon className="cursor-pointer" />}
                                                variant="outlined"
                                                className="cursor-pointer"
                                                onClick={() => forwardClick(location.state.repliedUuid)}
                                            />
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div >


                    </div >



                    {/*  */}

                </div>


                {/* {documentData && (<img src={`data:image/png;base64,${documentData}`}
                alt={`Document`} style={{ maxWidth: '70%', maxHeight: '100%' }} draggable="false"
                onContextMenu={handleContextMenu} />)}
            <button onClick={handleClose}>Close</button> */}

                {/* {
                documentData && (
                    <div>
                        <iframe
                            title="file-viewer"
                            src={`data:${getFileType()};base64,${documentData}`}
                            width="100%"
                            height="600px"
                            frameBorder="0"
                        />
                    </div>
                )
            } */}


                {showModal ?
                    <asdie id="menu" class="w-[60%] -ml-[-2rem] h-screen flex bg-white-800 border border-indigo-600 duration-700">
                        <div class="w-full flex flex-col text-black px-5 py-4 space-y-4">
                            <a onClick={() => closeAside()}
                                class="text-right text-4xl hover:text-red-400">&times;</a>
                            <TextField id="standard-basic" label="To" variant="standard" className='text-black'
                                value={location.state.zeroIndex.sender}
                                onInput={e => setTo(e.target.value)} />

                            <div className="flex justify-center mt-3">
                                <div className="relative rounded border-2 w-[100%] text-black h-[40rem] mt-8 p-4 place-content-center" style={{ width: "100%" }}>
                                    <div contentEditable="true" id='input' className="h-[28rem] overflow-y-auto outline-0 font-normal" style={{ whiteSpace: 'pre-wrap !important' }}
                                        type="input" value={content} onInput={e => setContent(e.currentTarget.innerHTML)}>
                                        {/* <textarea name="content" style="display:none;whiteSpace: 'pre-wrap';" /> */}
                                    </div>
                                    <div className="absolute bottom-10 justify-center">
                                        {selectedFiles.map((data, index) => {
                                            return (
                                                <div key={index} className="box-border h-10 w-96 border-2 p-2  border-current">
                                                    <span className="text-blue-600"> {data.name}</span>
                                                    <ClearIcon className="text-black absolute right-5 cursor-pointer" onClick={() => deleteAttachment(data.name)} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="absolute bottom-8 right-10" >
                                        {/* <Box sx={{ display: 'flex' }}> */}
                                        <Tooltip title="Msg Expiry">
                                            <IconButton
                                                onClick={handleClickMenu}
                                                size="small"
                                                sx={{ mb: 2 }}
                                                aria-controls={open ? 'expiry-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <Avatar sx={{ width: 32, height: 32 }}>E</Avatar>
                                            </IconButton>
                                        </Tooltip>

                                        <Menu
                                            anchorEl={anchorEl}
                                            id="expiry-menu"
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="top" value={value} onChange={handleChangeRadio}>
                                                        <FormControlLabel value="0" control={<Radio />} label="immediate" />
                                                        <FormControlLabel value="1" control={<Radio />} label="1 day" />
                                                        <FormControlLabel value="7" control={<Radio />} label="7 days" />
                                                        <FormControlLabel value="30" control={<Radio />} label="30 days" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </MenuItem>
                                        </Menu>
                                        {/* </Box> */}

                                        <div onClick={handleClick} className="text-black cursor-pointer ml-2 mb-2" title="Attachments">
                                            <InsertLinkIcon className="w-6 h-6" />
                                            <input type="file" ref={ref} className="hidden" multiple onChange={handleChangeinputAttachments} />
                                        </div>

                                        <div className="cursor-pointer">
                                            <Checkbox
                                                title="Reply"
                                                icon={<ReplyIcon className=" text-black" />}
                                                checkedIcon={<ReplyIcon color="blue" />}
                                                onChange={() => setReplySelect(true)}
                                            />
                                        </div>

                                        <div className="cursor-pointer">
                                            <Checkbox
                                                title="Forward"
                                                icon={<ShortcutIcon className=" text-black" />}
                                                checkedIcon={<ShortcutIcon color="blue" />}
                                                onChange={() => setForwardSelect(true)}
                                            />
                                        </div>
                                        {rawAttachments.length !== 0 ? (
                                            <div>
                                                <div className="cursor-pointer">
                                                    <Checkbox
                                                        title="View Only"
                                                        icon={<VisibilityOutlinedIcon className=" text-black" />}
                                                        checkedIcon={<VisibilityOutlinedIcon color="blue" />}
                                                        onChange={() => setViewOnly(true)}
                                                    />
                                                </div>
                                                <div className="cursor-pointer">
                                                    <Checkbox
                                                        title="Download Only"
                                                        icon={<FileDownloadOutlinedIcon className=" text-black" />}
                                                        checkedIcon={<FileDownloadOutlinedIcon color="blue" />}
                                                        onChange={() => setDownloadOnly(true)}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}


                                    </div>

                                </div>

                            </div>
                            <div className="flex justify-center">
                                <a className='w-24 text-center mt-6 rounded bg-[#31a0f633] border-2 border-[#31a0f640] text-black p-2 
                              hover:rounded hover:border-[#31a0f633] hover:bg-[#6366f1] hover:text-white cursor-pointer'
                                    onClick={Send}>
                                    Send
                                </a>
                            </div>
                        </div>
                    </asdie>
                    : ""
                }



            </div>
    )

}


export default MailBody